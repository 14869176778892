import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router';

import { JsonRpcSigner } from '@ethersproject/providers';
import {
  constants,
  ERC20RouterSDK,
  TeleportSDKFactory,
} from '@stichting-allianceblock-foundation/abridge-sdk';
import { Button, Icon, Notification } from '@stichting-allianceblock-foundation/components';
import { ReactComponent as ConnectWalletDesktopLogo } from 'assets/connect-wallet-desktop.svg';
import { ReactComponent as ConnectWalletMobileLogo } from 'assets/connect-wallet-mobile.svg';
import { Header } from 'components/Header';
import { SideMenu } from 'components/SideMenu';
import { NETWORK_CONFIG } from 'configs';
import { CHAINS_TYPES, TOKEN_FEE_DECIMALS } from 'configs/constants';
import { env } from 'environments';
import { ethers } from 'ethers';
import { useBreakpoint } from 'hooks/useBreakpoint';
import { useFeatureFlag } from 'hooks/useFeaturesFlags';
import useForceEnableBridgeOperation from 'hooks/useForceEnableBridgeOperation';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { useTargetSDK } from 'hooks/useTargetSDK';
import useWallet from 'hooks/useWallet';
import { BridgeTransfer } from 'pages/Bridge';
import { ClaimPage } from 'pages/Bridge/ClaimPage';
import { NftClaim } from 'pages/Nft/NftClaim';
import NftClaimCard from 'pages/Nft/NftClaimCard/NftClaimCard';
import NftClaimedPreview from 'pages/Nft/NftClaimedPreview/NftClaimedPreview';
// import NftHistory from "pages/Nft/NftHistory/NftHistory";
import NftPage from 'pages/Nft/NftPage/NftPage';
import NftPreview from 'pages/Nft/NftPreview/NftPreview';
import NftTransfer from 'pages/Nft/NftTransfer';
import {
  formatCurrency,
  fromWei,
  getBlackListByChainId,
  getNetworkByChainId,
  getTokenIcon,
} from 'utils';
import { currencySymbols, getCoingeckooIds, getTokenPrice } from 'utils/coingecko';
import { installWallet, isProviderAvailable } from 'utils/metamask';

import { getClaimCounters, getFormattedSdkClaims } from '../../helpers/Erc20Claims';
import { getFormattedSdkNftClaims, getNftClaimCounters } from '../../helpers/NftClaims';

import './Layout.scss';

const Layout = () => {
  const {
    config,
    isWalletConnected,
    supportedNetworks,
    isUnsupportedChain,
    currentNetwork,
    networkOptions,
    nftClaims,
    sdk,
    claims,
    networkSwitchScreen,
    bridgeOperationDisabled,
    setIsSideMenuOpen,
    setBridgeTransaction,
    setNftBridgeTransaction,
    setSDK,
    setServiceFeeOptions,
    setIsClaimLoaded,
    setIsNftsLoaded,
    setBridgeStatuses,
    setNftBridgeStatuses,
    setNetworkSwitchScreen,
    setAddNftCollectionAddress,
    setAddNftTokenId,
    setCurrentNetwork,
    setIsUnsupportedChain,
  } = useGlobalContext();

  const { isEnabled } = useFeatureFlag();
  const { connector, activate, library, chainId, isActive, account, provider } = useWallet();

  const [activatingConnector, setActivatingConnector] = useState();
  const { width, greaterThan } = useBreakpoint();
  const { t } = useTranslation();
  const [signer, setSigner] = useState<JsonRpcSigner>();
  const providerAvailable = isProviderAvailable();
  // Load SDK with target network
  useTargetSDK();

  const maxAttempts: number = 10;

  // This hook will try to enable the bridge operation if it is disabled
  useForceEnableBridgeOperation();

  useEffect(() => {
    const deactivateNetworkSwitchScreen = () => {
      if (
        currentNetwork.chainId === chainId &&
        currentNetwork.chainId === networkSwitchScreen.network.chainId &&
        networkSwitchScreen.connecting
      ) {
        setNetworkSwitchScreen(prevNetworkSwitchScreen => {
          return {
            ...prevNetworkSwitchScreen,
            connecting: false,
          };
        });
      }
    };

    deactivateNetworkSwitchScreen();
  }, [
    chainId,
    currentNetwork.chainId,
    networkSwitchScreen.connecting,
    networkSwitchScreen.network.chainId,
    setNetworkSwitchScreen,
  ]);

  useEffect(() => {
    setIsClaimLoaded(true);
    setIsNftsLoaded(true);
  }, [setIsClaimLoaded, setIsNftsLoaded, account]);

  useEffect(() => {
    function getToBeClaimedCounter(claims: Erc20Claim[]) {
      const { counterClaimed, counterNotClaimed } = getClaimCounters(claims);

      setBridgeStatuses(prevBridgeStatuses => {
        return {
          bridge: {
            ...prevBridgeStatuses.bridge,
            activeClaims: counterClaimed,
            inActiveClaims: counterNotClaimed,
          },
        };
      });
    }

    if (!claims?.length) {
      setIsClaimLoaded(true);
    }
    const updateClaims = async () => {
      if (sdk && account) {
        try {
          const formattedClaims = await getFormattedSdkClaims(
            sdk,
            account,
            currentNetwork.chainTargetId,
          );
          getToBeClaimedCounter(formattedClaims);
        } catch (error) {
          console.error(error);
        }
        setIsClaimLoaded(false);
      }
    };

    if (account && currentNetwork.chainTargetId) {
      updateClaims();
    }
  }, [
    account,
    claims?.length,
    currentNetwork.chainTargetId,
    sdk,
    setBridgeStatuses,
    setIsClaimLoaded,
  ]);

  useEffect(() => {
    function getNftCounters(claimsNft: FormattedNftClaims[]) {
      const { counterClaimed, counterNotClaimed } = getNftClaimCounters(claimsNft);
      setNftBridgeStatuses(nftBridgeStatuses => {
        return {
          bridge: {
            ...nftBridgeStatuses.bridge,
            activeNftClaims: counterClaimed,
            inActiveNftClaims: counterNotClaimed,
          },
        };
      });
    }

    setAddNftCollectionAddress('');
    setAddNftTokenId('');

    if (!nftClaims?.length) {
      setIsNftsLoaded(true);
    }
    const loadNftClaims = async () => {
      try {
        if (sdk && account && currentNetwork.chainTargetId && networkOptions) {
          const formattedNftClaims = await getFormattedSdkNftClaims(
            sdk,
            account,
            currentNetwork.chainTargetId,
            networkOptions,
            isEnabled('ERC1155'),
          ).catch(error => {
            console.error('ERROR CATCHED FROM FORMAT NFT', error);
            setIsNftsLoaded(false);
          });
          if (formattedNftClaims) {
            getNftCounters(Object.values(formattedNftClaims));
            setIsNftsLoaded(false);
          }
        }
      } catch (error) {
        setIsNftsLoaded(false);
        console.error('LOAD NFT CLAIMS ERROR', error);
      }
    };

    loadNftClaims();
  }, [
    account,
    currentNetwork.chainTargetId,
    isEnabled,
    networkOptions,
    nftClaims,
    sdk,
    setAddNftCollectionAddress,
    setAddNftTokenId,
    setIsNftsLoaded,
    setNftBridgeStatuses,
  ]);

  useEffect(() => {
    const loadSigner = async () => {
      if (library) {
        try {
          setSigner(library.getSigner());
        } catch (error) {
          console.error('loadSigner', error);
        }
      }
    };

    loadSigner();
  }, [library]);

  useEffect(() => {
    const loadSDK = async () => {
      // We need to wait until the provider network is loaded in the signer to load the SDK
      // Because we use it in the SDK to get the signer's chainId
      if (
        (!provider && chainId === currentNetwork.chainId) ||
        // FIXME: check wallet.connected with Solana
        // @ts-ignore
        (provider?.wallet.connected && -1 === currentNetwork.chainId)
      ) {
        const blackListedTokens = getBlackListByChainId(currentNetwork.chainId);

        const signerOrProvider = provider || signer;
        if (signerOrProvider) {
          const abridgeBackend = env.ABRIDGE_BACKEND_URL;

          const _sdk = await TeleportSDKFactory.create({
            chainType: CHAINS_TYPES[currentNetwork.walletType] as constants.ChainType,
            signerOrProvider,
            optionalParams: {
              validatorAllowedVersion: env.VALIDATOR_ALLOWED_VERSION,
              validators: env.VALIDATORS,
              isProduction: env.IS_PRODUCTION || env.IS_STAGING,
              rpcs: Object.keys(NETWORK_CONFIG).reduce((acc, key) => {
                const { chainId, rpcUrl } = NETWORK_CONFIG[key].network;
                return { ...acc, [chainId]: rpcUrl };
              }, {}),
            },
            modulesOptionalParams: {
              ERC20: { abridgeBackend, blackListedTokens },
              ERC721: { abridgeBackend, blackListedTokens },
              ERC1155: { abridgeBackend, blackListedTokens },
            },
          });
          setSDK(_sdk);
        }
      } else {
        setSDK(null);
      }
    };

    loadSDK();
  }, [currentNetwork.chainId, chainId, currentNetwork.walletType, provider, setSDK, signer]);

  useEffect(() => {
    // So, we make sure that the side menu always is close when meets lg breakpoint
    if (greaterThan('lg')) {
      setIsSideMenuOpen(false);
    }
  }, [greaterThan, setIsSideMenuOpen, width]);

  useEffect(() => {
    let attemptCount: number = 0;
    const loadServiceFeeOptions = async () => {
      if (sdk && account && currentNetwork) {
        try {
          const serviceFeeTokens: ServiceFeeToken[] = [];

          // Get native token service fee
          const serviceFeeBN = await sdk.getTeleportFee();

          const nativeTokenServiceFee = fromWei(
            serviceFeeBN.toString(),
            currentNetwork.nativeCurrency?.decimals,
          );

          const nativeTokenCoingeckoId = getCoingeckooIds(
            currentNetwork.nativeCurrency?.symbol?.toLowerCase(),
          ).id;

          let formattedNativeTokenPrice: string = `(${
            currencySymbols[config.serviceFeeCurrency]
          } N/A)`;

          try {
            const nativeTokenPrice = await getTokenPrice(
              nativeTokenCoingeckoId,
              config.serviceFeeCurrency,
            );

            formattedNativeTokenPrice = formatCurrency(
              Number(nativeTokenServiceFee) * nativeTokenPrice,
              TOKEN_FEE_DECIMALS,
              currencySymbols[config.serviceFeeCurrency],
            );
          } catch (err) {
            console.error('loadServiceFeeOptions nativeTokenCoingeckoId getTokenPrice', err);
          }

          const nativeServiceFeeToken: ServiceFeeToken = {
            amount: nativeTokenServiceFee,
            amountInCurrency: formattedNativeTokenPrice,
            name: currentNetwork.nativeCurrency?.name,
            symbol: currentNetwork.nativeCurrency?.symbol,
            decimals: currentNetwork.nativeCurrency?.decimals,
            icon: currentNetwork.chainIcon,
            address: ethers.constants.AddressZero,
          };
          serviceFeeTokens.push(nativeServiceFeeToken);

          // Get service fee tokens
          const feeTokensAddresses: string[] = await sdk
            .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
            .getFeeTokens();
          const feeTokensDetails = await sdk
            .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
            .getTokenDetailsMulticall(feeTokensAddresses, account);

          for (const feeToken of feeTokensDetails) {
            if (feeToken.address && feeToken.symbol) {
              const serviceFeeAmountBN = await sdk
                .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
                .getFeeAmountByToken(feeToken.address);
              const serviceFeeAmount = fromWei(serviceFeeAmountBN, feeToken.decimals);

              const coingeckoId = getCoingeckooIds(feeToken?.symbol?.toLowerCase()).id;

              let formattedPrice: string = `(${currencySymbols[config.serviceFeeCurrency]} N/A)`;

              try {
                const priceInCurrency = await getTokenPrice(coingeckoId, config.serviceFeeCurrency);

                formattedPrice = formatCurrency(
                  Number(serviceFeeAmount) * priceInCurrency,
                  TOKEN_FEE_DECIMALS,
                  currencySymbols[config.serviceFeeCurrency],
                );
              } catch (err) {
                console.error('loadServiceFeeOptions feeToken getTokenPrice', err);
              }

              const serviceFeeTokenIcon = getTokenIcon({
                name: feeToken.name,
                symbol: feeToken.symbol,
              });

              const serviceFeeToken: ServiceFeeToken = {
                ...feeToken,
                amount: serviceFeeAmount,
                amountInCurrency: formattedPrice,
                icon: serviceFeeTokenIcon,
              };

              serviceFeeTokens.push(serviceFeeToken);
            }
          }

          const filteredServiceFeeTokens = await Promise.all(
            serviceFeeTokens.filter(async feeToken => {
              const isBlackListed = await sdk
                .dapp<ERC20RouterSDK>(ERC20RouterSDK.DAPP_NAME)
                .checkTokenBlackListed(feeToken.address);
              return isBlackListed;
            }),
          );

          setServiceFeeOptions(filteredServiceFeeTokens);
        } catch (err) {
          console.error('loadServiceFeeOptions', err);
          setServiceFeeOptions([]);
        }
      }
    };
    try {
      loadServiceFeeOptions();
    } catch (error) {
      attemptCount++;
      console.error(`loadServiceFeeOptions attemptCount:${attemptCount}`, error);
      if (attemptCount < maxAttempts) {
        loadServiceFeeOptions();
      }
    }
    return () => {
      if (sdk) setServiceFeeOptions([]);
    };
  }, [account, config.serviceFeeCurrency, currentNetwork, sdk, setServiceFeeOptions]);

  useEffect(() => {
    const loadBridgeTransactionRecipientIfActive = () => {
      if (sdk && isActive && account) {
        setBridgeTransaction(prevBridgeTransaction => {
          return {
            ...prevBridgeTransaction,
            recipient: account,
          };
        });
        setNftBridgeTransaction(prevNftBridgeTransaction => {
          return {
            ...prevNftBridgeTransaction,
            recipient: account,
          };
        });
      }
    };

    loadBridgeTransactionRecipientIfActive();
  }, [account, isActive, sdk, setBridgeTransaction, setNftBridgeTransaction]);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  async function handleConnectInjected() {
    await activate();
  }

  useEffect(() => {
    const changeNetworkIfNotTheSame = async () => {
      if (
        chainId &&
        !Object.values(supportedNetworks).some(item => item.network.chainId === chainId)
      ) {
        setIsUnsupportedChain(true);
      } else if (chainId && (chainId !== currentNetwork.chainId || isUnsupportedChain)) {
        const metamaskNetwork = getNetworkByChainId(chainId);
        if (metamaskNetwork) {
          setCurrentNetwork(metamaskNetwork);
          setIsUnsupportedChain(false);
        }
      }
    };

    changeNetworkIfNotTheSame();
  }, [
    chainId,
    currentNetwork,
    supportedNetworks,
    isUnsupportedChain,
    isWalletConnected,
    setCurrentNetwork,
    setIsUnsupportedChain,
  ]);

  const renderNotSupportedChain = () => {
    return (
      <div className="network-switch-screen d-flex flex-column justify-content-center align-items-center pulse">
        <div className="text-main text-medium">{t('layout:unsupportedChain.title')}</div>
        <Icon size={32} name="warning" className="mt-4" />

        <div className="text-main text-center">
          <div className="line my-5 mx-8"></div>
          <Trans
            i18nKey="layout:unsupportedChain.description"
            components={{ span: <span /> }}
            values={{ chainName: networkSwitchScreen.network.chainName }}
          />
        </div>
      </div>
    );
  };

  const renderNetworkSwitchScreen = () => {
    return (
      <div className="network-switch-screen d-flex flex-column justify-content-center align-items-center pulse">
        <div className="text-main text-medium">{t('layout:networkSwitchScreen.title')}</div>
        <Icon size={32} name={networkSwitchScreen.network.chainIcon} className="mt-4" />
        <div className="text-main text-bold mt-3">{networkSwitchScreen.network.chainName}</div>

        <div className="text-main text-center">
          <div className="line my-5 mx-8"></div>
          <Trans
            i18nKey="layout:networkSwitchScreen.description"
            components={{ span: <span /> }}
            values={{ chainName: networkSwitchScreen.network.chainName }}
          />
        </div>
      </div>
    );
  };

  const renderNotConnected = (disabled: boolean = false) => {
    return (
      <div>
        {disabled && (
          <Notification
            type="danger"
            closable={false}
            text={
              <span>
                <Trans
                  i18nKey="layout:notificationMessages.bridgeOperationDisabled"
                  components={{ a: <a /> }}
                />
              </span>
            }
          />
        )}
        <div className="mt-8 mb-5 my-md-7 fade-in-400">
          <div className="text-medium text-bold text-main mb-3 mr-5">
            {t('layout:notConnected.title')}
          </div>

          <div className="text-main">
            <Trans i18nKey="layout:notConnected.subtitle" components={{ span: <span /> }} />
          </div>

          <div className="d-flex justify-content-center mt-4">
            {greaterThan('md') ? (
              <ConnectWalletDesktopLogo className="max-width-100" />
            ) : (
              <ConnectWalletMobileLogo className="max-width-100" />
            )}
          </div>

          <div className="mt-4 mb-5">
            <div className="text-medium text-bold text-main text-center mb-3">
              {t('layout:notConnected.welcome', {
                productName: config.productName,
              })}
            </div>
            <div className="text-center">
              <Trans
                i18nKey="layout:notConnected.welcomeDescription"
                components={{ span: <span /> }}
                values={{ productName: config.productName }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Button type="primary" disabled={disabled} onClick={() => handleConnectInjected()}>
              <Icon color="uiElementSecondary" size={20} name="wallet" className="mr-3" />
              <span>{t('walletButton:button.connectWallet')}</span>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderConnected = () => {
    return (
      <div className="fade-in-400">
        <Switch>
          <Route exact path="/" component={BridgeTransfer} />
          <Route exact path="/bridge/claim" component={ClaimPage} />
          <Route exact path="/bridge-nft" component={NftPage} />
          <Route exact path="/bridge-nft/transfer" component={NftTransfer} />
          <Route path="/bridge-nft/preview/:address/:tokenId" component={NftPreview} />
          <Route path="/bridge-nft/preview/:address" component={NftPreview} />
          {/* <Route exact path="/bridge-nft/history" component={NftHistory} /> */}
          <Route exact path="/bridge-nft/claim-nft-card/:transmissionId" component={NftClaimCard} />
          <Route
            exact
            path="/bridge-nft/claim/preview/:transmissionId"
            component={NftClaimedPreview}
          />
          <Route path="/bridge-nft/claim" component={NftClaim} />
        </Switch>
      </div>
    );
  };

  const renderNoProvider = () => {
    return (
      <div>
        <div className="mt-8 mb-5 my-md-7 fade-in-400">
          <div className="text-medium text-bold text-main mb-3 mr-5">
            {t('layout:notConnected.title')}
          </div>

          <div className="text-main">
            <Trans i18nKey="layout:notConnected.subtitle" components={{ span: <span /> }} />
          </div>

          <div className="d-flex justify-content-center mt-4">
            {greaterThan('md') ? (
              <ConnectWalletDesktopLogo className="max-width-100" />
            ) : (
              <ConnectWalletMobileLogo className="max-width-100" />
            )}
          </div>

          <div className="mt-4 mb-5">
            <div className="text-medium text-bold text-main text-center mb-3">
              {t('layout:notConnected.welcome', {
                productName: config.productName,
              })}
            </div>
            <div className="text-center">
              <Trans
                i18nKey="layout:notConnected.welcomeDescriptionInstall"
                components={{ span: <span /> }}
                values={{ productName: config.productName }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Button type="primary" onClick={() => installWallet()}>
              <Icon color="uiElementSecondary" size={20} name="wallet" className="mr-3" />
              <span>{t('walletButton:button.install')}</span>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <SideMenu
        logo={config.companyLogoUrl}
        title={config.companyName}
        subtitle={t('sideMenu:subtitle')}
      />
      <div className="layout-content">
        <Header />
        <div className="content-wrapper">
          <div className="content">
            {bridgeOperationDisabled
              ? renderNotConnected(true)
              : !providerAvailable
              ? renderNoProvider()
              : !networkSwitchScreen.connecting
              ? isWalletConnected[currentNetwork?.walletType]
                ? isUnsupportedChain
                  ? renderNotSupportedChain()
                  : renderConnected()
                : renderNotConnected(bridgeOperationDisabled)
              : renderNetworkSwitchScreen()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
