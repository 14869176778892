import { WALLET_TYPE } from 'configs/constants';
import { env } from 'environments';

export const config: NetworkConfig = {
  eth: {
    network: {
      symbol: 'ETH',
      chainId: 1,
      chainName: 'Ethereum',
      chainIcon: 'ethereum',
      chainTargetId: 1,
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrl: env.INFURA_ID
        ? `https://mainnet.infura.io/v3/${env.INFURA_ID}`
        : env.RPC_MAINNET_ETHEREUM,
      blockExplorerUrl: env.BLOCK_EXPLORER_MAINNET_ETHEREUM,
      refreshClaimTimestamp: 15000,
      walletType: WALLET_TYPE.METAMASK,
    },
    tokenAddresses: {
      NXRA: {
        address: '0x644192291cc835A93d6330b24EA5f5FEdD0eEF9e',
        nameOverride: 'NXRA (Nexera)',
      },
      NAI: {
        address: '0x5Ac34C53A04B9aaA0BF047e7291fb4e8A48f2A18',
        nameOverride: 'NAI (Nuklai)',
      },
      DUA: {
        address: '0x6c249b6F6492864d914361308601A7aBb32E68f8',
        nameOverride: 'DUA (Brillion)',
        whitelistedChains: [2, 4, 6, 7],
      },
      NITRO: {
        address: '0x05F84489043d5a8818747333A3CB51D2E6877E46',
        whitelistedChains: [3],
      },
      LEOX: '0xa444ec96ee01bb219a44b285de47bf33c3447ad5',
      EJS: '0x96610186f3ab8d73ebee1cf950c750f3b1fb79c2',
      IXS: '0x73d7c860998ca3c01ce8c808f5577d94d545d1b4',
      UDO: '0xea3983Fc6D0fbbC41fb6F6091f68F3e08894dC06',
      OCEAN: '0x967da4048cd07ab37855c090aaf366e4ce1b9f48',
    },
    tokensBlacklist: [
      '0xdAC17F958D2ee523a2206206994597C13D831ec7', // USDT
      '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // USDC
      '0x6b175474e89094c44da98b954eedeac495271d0f', // DAI
      '0x00a8b738E453fFd858a7edf03bcCfe20412f0Eb0', // ALBT
    ],
  },
  bsc: {
    network: {
      symbol: 'BSC',
      chainId: 56,
      chainName: 'Smart Chain',
      chainIcon: 'binance',
      chainTargetId: 2,
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrl: env.RPC_MAINNET_BSC,
      blockExplorerUrl: env.BLOCK_EXPLORER_MAINNET_BSC,
      refreshClaimTimestamp: 15000,
      walletType: WALLET_TYPE.METAMASK,
    },
    tokenAddresses: {
      NXRA: {
        address: '0x644192291cc835A93d6330b24EA5f5FEdD0eEF9e',
        nameOverride: 'NXRA (Nexera)',
      },
      DUA: {
        address: '0x6c249b6F6492864d914361308601A7aBb32E68f8',
        nameOverride: 'DUA (Brillion)',
        whitelistedChains: [1, 4, 6, 7],
      },
      LEOX: '0x2119D39cADc3fF24732563Be30A01458273B42cA',
      WEJS: '0x09f423aC3C9baBBfF6F94D372b16E4206e71439f',
      WUDO: '0x70802af0ba10dd5bb33276b5b37574b6451db3d9',
    },
    tokensBlacklist: [
      '0x55d398326f99059ff775485246999027b3197955', // USDT
      '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', // USDC
      '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3', // DAI
    ],
  },
  avalanche: {
    network: {
      symbol: 'AVAX',
      chainId: 43114,
      chainName: 'Avalanche C-Chain',
      chainIcon: 'avalanche',
      chainTargetId: 3,
      nativeCurrency: {
        name: 'AVAX',
        symbol: 'AVAX',
        decimals: 18,
      },
      rpcUrl: env.RPC_MAINNET_AVAX,
      blockExplorerUrl: env.BLOCK_EXPLORER_MAINNET_AVAX,
      refreshClaimTimestamp: 15000,
      walletType: WALLET_TYPE.METAMASK,
    },
    tokenAddresses: {
      NXRA: {
        address: '0x644192291cc835A93d6330b24EA5f5FEdD0eEF9e',
        nameOverride: 'NXRA (Nexera)',
      },
      NAI: {
        address: '0x5Ac34C53A04B9aaA0BF047e7291fb4e8A48f2A18',
        nameOverride: 'NAI (Nuklai)',
      },
      NITRO: {
        address: '0x05F84489043d5a8818747333A3CB51D2E6877E46',
        whitelistedChains: [1],
      },
      LEOX: '0x2119D39cADc3fF24732563Be30A01458273B42cA',
    },
    tokensBlacklist: [
      '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7', // USDT
      '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', // USDC
      '0xd586e7f844cea2f87f50152665bcbc2c279d8d70', // DAI
      '0xD5750209c816e498B36d360cD5e67184cAf79eAa', // rALBT
      '0xb418417374fcA27bb54169D3C777492e6Fe17Ee7', // DUA
    ],
  },
  polygon: {
    network: {
      symbol: 'MATIC',
      chainId: 137,
      chainName: 'Polygon Matic',
      chainIcon: 'matic',
      chainTargetId: 4,
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrl: env.RPC_MAINNET_POLYGON,
      blockExplorerUrl: env.BLOCK_EXPLORER_MAINNET_POLYGON,
      refreshClaimTimestamp: 15000,
      walletType: WALLET_TYPE.METAMASK,
    },
    tokenAddresses: {
      NXRA: {
        address: '0x644192291cc835A93d6330b24EA5f5FEdD0eEF9e',
        nameOverride: 'NXRA (Nexera)',
      },
      DUA: {
        address: '0x6c249b6F6492864d914361308601A7aBb32E68f8',
        nameOverride: 'DUA (Brillion)',
        whitelistedChains: [1, 2, 6, 7],
      },
      LEOX: '0x2119D39cADc3fF24732563Be30A01458273B42cA',
      WIXS: '0x1BA17C639BdaeCd8DC4AAc37df062d17ee43a1b8',
    },
    tokensBlacklist: [
      '0xc2132d05d31c914a87c6611c10748aeb04b58e8f', // USDT
      '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359', // USDC
      '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', // DAI
    ],
  },
  arbitrum: {
    network: {
      symbol: 'ARBITRUM',
      chainId: 42161,
      chainName: 'Arbitrum',
      chainIcon: 'arbitrum',
      chainTargetId: 6,
      nativeCurrency: {
        name: 'AETH',
        symbol: 'AETH',
        decimals: 18,
      },
      rpcUrl: env.RPC_MAINNET_ARBITRUM,
      blockExplorerUrl: env.BLOCK_EXPLORER_MAINNET_ARBITRUM,
      refreshClaimTimestamp: 15000,
      walletType: WALLET_TYPE.METAMASK,
    },
    tokenAddresses: {
      NXRA: {
        address: '0x644192291cc835A93d6330b24EA5f5FEdD0eEF9e',
        nameOverride: 'NXRA (Nexera)',
      },
      DUA: {
        address: '0x6c249b6F6492864d914361308601A7aBb32E68f8',
        nameOverride: 'DUA (Brillion)',
        whitelistedChains: [1, 2, 4, 7],
      },
      LEOX: '0x2119D39cADc3fF24732563Be30A01458273B42cA',
    },
    tokensBlacklist: [
      '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9', // USDT
      '0xaf88d065e77c8cC2239327C5EDb3A432268e5831', // USDC
      '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1', // DAI
    ],
  },
  optimism: {
    network: {
      symbol: 'OPTIMISM',
      chainId: 10,
      chainName: 'Optimism',
      chainIcon: 'optimism',
      chainTargetId: 7,
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrl: env.RPC_MAINNET_OPTIMISM,
      blockExplorerUrl: env.BLOCK_EXPLORER_MAINNET_OPTIMISM,
      refreshClaimTimestamp: 15000,
      walletType: WALLET_TYPE.METAMASK,
    },
    tokenAddresses: {
      NXRA: {
        address: '0x644192291cc835A93d6330b24EA5f5FEdD0eEF9e',
        nameOverride: 'NXRA (Nexera)',
      },
      DUA: {
        address: '0x6c249b6F6492864d914361308601A7aBb32E68f8',
        nameOverride: 'DUA (Brillion)',
        whitelistedChains: [1, 2, 4, 6],
      },
      LEOX: '0x2119D39cADc3fF24732563Be30A01458273B42cA',
    },
    tokensBlacklist: [
      '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58', // USDT
      '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85', // USDC
      '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1', // DAI
    ],
  },
  base: {
    network: {
      symbol: 'BASE',
      chainId: 8453,
      chainName: 'Base',
      chainIcon: 'base',
      chainTargetId: 12,
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrl: env.RPC_MAINNET_BASE,
      blockExplorerUrl: env.BLOCK_EXPLORER_MAINNET_BASE,
      refreshClaimTimestamp: 15000,
      walletType: WALLET_TYPE.METAMASK,
    },
    tokenAddresses: {},
    tokensBlacklist: [
      '0xc0E49f8C615d3d4c245970F6Dc528E4A47d69a44', // USDT
      '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', // USDC
      '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb', // DAI
    ],
  },
};
