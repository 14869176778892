import { WALLET_TYPE } from 'configs/constants';
import { env } from 'environments';

export const config: NetworkConfig = {
  eth: {
    network: {
      symbol: 'ETH',
      chainId: 11155111,
      chainName: 'Ethereum - Sepolia',
      chainIcon: 'ethereum',
      chainTargetId: 1,
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrl: env.RPC_TESTNET_ETHEREUM,
      blockExplorerUrl: env.BLOCK_EXPLORER_TESTNET_ETHEREUM,
      refreshClaimTimestamp: 15000,
      walletType: WALLET_TYPE.METAMASK,
    },
    tokenAddresses: {
      NAI: {
        address: '0x5Ac34C53A04B9aaA0BF047e7291fb4e8A48f2A18',
        nameOverride: 'NAI (Nuklai)',
      },
      DUA: {
        address: '0x6c249b6F6492864d914361308601A7aBb32E68f8',
        nameOverride: 'DUA (Brillion)',
        whitelistedChains: [2, 4, 6, 7],
      },
      LEOX: '0xb1A6C622f933A475e9FE6829e393F3A3343fc120',
      USDT: '0x7169D38820dfd117C3FA1f22a697dBA58d90BA06',
    },
    tokensBlacklist: [
      '0xaa8e23fb1079ea71e0a56f48a2aa51851d8433d0', // USDT
      '0x1c7d4b196cb0c7b01d743fbc6116a902379c7238', // USDC
    ],
  },
  bsc: {
    network: {
      symbol: 'BNB',
      chainId: 97,
      chainName: 'Smart Chain - Testnet',
      chainIcon: 'binance',
      chainTargetId: 2,
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrl: env.RPC_TESTNET_BSC,
      blockExplorerUrl: env.BLOCK_EXPLORER_TESTNET_BSC,
      refreshClaimTimestamp: 15000,
      walletType: WALLET_TYPE.METAMASK,
    },
    tokenAddresses: {
      BUSD: '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
      WLEOX: '0x4e88684a50dE5C66d62B8A71588E9e0bd2970223',
      WEWT: '0x4AC73d9455aE16d7F342085c2060E9A3d68e06B9',
    },
    tokensBlacklist: [
      '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd', // USDT
      '0xEC5dCb5Dbf4B114C9d0F65BcCAb49EC54F6A0867', // DAI
    ],
  },
  arbitrum: {
    network: {
      symbol: 'ARBITRUM',
      chainId: 421614,
      chainName: 'Arbitrum Sepolia',
      chainIcon: 'arbitrum',
      chainTargetId: 6,
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrl: env.RPC_TESTNET_ARBITRUM_SEPOLIA,
      blockExplorerUrl: env.BLOCK_EXPLORER_MAINNET_ARBITRUM,
      refreshClaimTimestamp: 15000,
      walletType: WALLET_TYPE.METAMASK,
    },
    tokenAddresses: {},
    tokensBlacklist: [
      '0x30fA2FbE15c1EaDfbEF28C188b7B8dbd3c1Ff2eB', // USDT
      '0x75faf114eafb1bdbe2f0316df893fd58ce46aa4d', // USDC
    ],
  },
  avalanche: {
    network: {
      symbol: 'AVAX',
      chainId: 43113,
      chainName: 'Avalanche - Fuji',
      chainIcon: 'avalanche',
      chainTargetId: 3,
      nativeCurrency: {
        name: 'AVAX',
        symbol: 'AVAX',
        decimals: 18,
      },
      rpcUrl: env.RPC_TESTNET_AVAX,
      blockExplorerUrl: env.BLOCK_EXPLORER_TESTNET_AVAX,
      refreshClaimTimestamp: 15000,
      walletType: WALLET_TYPE.METAMASK,
    },
    tokenAddresses: {
      LEOX: '0xfa372284eBfF4C8635fF939f7eF2cA1c525C43e7',
      WEWT: '0x36BC80133aD7E802b6d93393993e49E0cAfA3CEA',
    },
    tokensBlacklist: [
      '0xE0a9A67E52D46C6F1E95771717A597fB105A31b7', // rALBT
      '0x5425890298aed601595a70AB815c96711a31Bc65', // USDC
    ],
  },
  // polygon: {
  //   network: {
  //     symbol: 'MATIC',
  //     chainId: 80002,
  //     chainName: 'Polygon - Amoy',
  //     chainIcon: 'matic',
  //     chainTargetId: 4,
  //     nativeCurrency: {
  //       name: 'MATIC',
  //       symbol: 'MATIC',
  //       decimals: 18,
  //     },
  //     rpcUrl:
  //       process.env.RPC_POLYGON_AMOY_TESTNET ,
  //     blockExplorerUrl:
  //       process.env.BLOCK_EXPLORER_POLYGON_AMOY_TESTNET,
  //     refreshClaimTimestamp: 15000,
  //     walletType: WALLET_TYPE.METAMASK,
  //   },
  //   tokenAddresses: {
  //     LEOX: '0x07d0Cc278a56922AE1f55c00A2EDaf439270a9ee',
  //     WEWT: '0x829a63Dd608Bb60a9f8144d6f36AA9b7053c758F',
  //   },
  // },
  // optimism: {
  //   network: {
  //     symbol: 'OPTIMISM',
  //     chainId: 11155420,
  //     chainName: 'Optimism - Sepolia',
  //     chainIcon: 'optimism',
  //     chainTargetId: 7,
  //     nativeCurrency: {
  //       name: 'ETH',
  //       symbol: 'ETH',
  //       decimals: 18,
  //     },
  //     rpcUrl: env.RPC_OPTIMISM_SEPOLIA_TESTNET,
  //     blockExplorerUrl: env.BLOCK_EXPLORER_OPTIMISM_SEPOLIA_TESTNET,
  //     refreshClaimTimestamp: 15000,
  //     walletType: WALLET_TYPE.METAMASK,
  //   },
  //   tokenAddresses: {},
  //   tokensBlacklist: [
  //     '0x5fd84259d66cd46123540766be93dfe6d43130d7', // USDC
  //   ],
  // },
};
